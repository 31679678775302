<template>
  <b-card
      footer-class="text-muted"
  >
    <b-overlay
        :show="show"
        no-wrap
    />
    <b-row class="mb-1">
      <b-col style="margin-bottom: 40px"
             cols="12"
      >

        <h3 style="text-align: center">Paciente: {{ user.name }}</h3>
        <h4 v-if="select_medical" style="text-align: center; margin-top: 20px">Medico: {{ select_medical.value }}</h4>
        <div class="mt-3" style="display: flex; justify-content: center" align="center">
          <div>
            <img :src="avatarImage ? avatarImage : avatarImageTmp" style="width: 100px; margin-bottom: 20px"/>
            <b-form-file
                @change="handleImage()"
                id="file_patient_photo"
                placeholder="Subir Imagen."
                drop-placeholder="Drop file here..."
            />
          </div>
        </div>
      </b-col>

      <b-col
          lg="6"
      >

        <h4 style="text-align: center" v-if="user.birthday">Fecha de nacimiento: {{ dateStringMx(user.birthday) }}</h4>
        <h4 style="text-align: center" v-if="user.birthday">Edad: {{ yearsCalculate(user.birthday) }} Años</h4>
      </b-col>
      <b-col
          lg="6"
      >
        <h4 style="text-align: center">Numero expediente: {{ user.patient_file_number }}</h4>
        <h4 style="text-align: center">Celular: {{ user.mobile }}</h4>

      </b-col>
    </b-row>
    <b-form @submit.prevent="updateUser(user)">
      <b-row>
        <b-col
            cols="12" lg="12"
        >
          <app-collapse>
            <app-collapse-item title="Información General" :is-visible="true">
              <b-row>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Nombre"
                      label-for="Nombre"
                  >
                    <b-form-input
                        v-model="user.name"
                        id="basicInput"
                        placeholder="Paciente"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="2"
                >
                  <b-form-group

                      label="Numero expediente"
                      label-for="Numero expediente"
                  >
                    <b-form-input
                        v-model="user.patient_file_number"
                        readonly="readonly"
                        placeholder="Numbero expediente"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group>
                    <h5>Fecha de nacimiento</h5>
                    <flat-pickr
                        v-model="user.birthday"
                        class="form-control"
                        :config="{ enableTime: false,dateFormat: 'Y-m-d',  locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        },
        months: {
          shorthand: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Оct', 'Nov', 'Dic'],
          longhand: ['Enero', 'Febrero', 'Мarzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        }}}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="2">
                  <b-form-group label="Sexo" label-for="h-email">
                    <v-select
                        v-model="gender_select"
                        @input="changeGender()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="genders"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group label="Género:" label-for="h-email">
                    <v-select
                        v-model="identified_gender_select"
                        @input="changeIdentifiedGender()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="gendersIdentified"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group

                      label="Puesto de trabajo"
                      label-for="Puesto de trabajo"
                  >
                    <b-form-input
                        v-model="user.function"
                        placeholder="Puesto de trabajo"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Telefono"
                      label-for="Telefono"
                  >
                    <b-form-input
                        @keypress="isNumber($event)"
                        v-model="user.phone"
                        placeholder="Telefono"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Celular"
                      label-for="Celular"
                  >
                    <b-form-input
                        @keypress="isNumber($event)"
                        v-model="user.mobile"
                        placeholder="Celular"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                    cols="6" lg="3"
                >
                  <b-form-group
                      label="Correo"
                      label-for="Correo"
                  >
                    <b-form-input
                        v-model="user.email"
                        type="email"
                        placeholder="Correo"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Doctores"
                      label-for="h-email"
                  >
                    <v-select
                        @input="changeMedical"
                        v-model="select_medical"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="medicals"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4" style="margin-top: 15px">
                  <b-form-checkbox
                      @change="changeExtraValue()"
                      v-model="user.check_medical"
                      :value="user.check_medical ? true : ''"
                  >
                    Medico Relacionado
                  </b-form-checkbox>
                  <div v-if="user.check_medical" style="margin-top: 15px">
                    <b-form-group
                        label="Medico Relacionado"
                        label-for="h-email"
                    >
                      <v-select
                          @input="change_extra_medical"

                          v-model="extra_select"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="value"
                          :options="medicals"
                      />
                    </b-form-group>
                  </div>
                </b-col>

                <b-col cols="6" lg="4" style="margin-top: 15px">
                  <b-form-checkbox
                      @change="changeChemoValue()"
                      v-model="user.chemo_suspension"
                      :value="user.chemo_suspension ? true : ''"
                  >
                    Suspension de quimioterapia
                  </b-form-checkbox>
                  <div v-if="user.chemo_suspension" style="margin-top: 15px">
                    <b-form-group
                        label="Razón"
                        label-for="h-email"
                    >
                      <v-select
                          @input="change_chemo"

                          v-model="chemo_select"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="value"
                          :options="chemo_options"
                      />
                    </b-form-group>
                  </div>
                  <div v-if="user.chemo_suspension" style="margin-top: 15px">
                    <b-form-group
                        label="Comentarios"
                        label-for="h-email"
                    >
                      <b-form-textarea v-model="user.comments_suspension">

                      </b-form-textarea>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col cols="6" lg="4" style="margin-top: 15px">
                  <div v-if="allergiesInfo" style="margin-top: 15px">
                    <b-form-group
                        label="Allergias"
                        label-for="h-email"
                    >
                      <b-form-textarea v-model="allergiesInfo" disabled>

                      </b-form-textarea>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Domicilio">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="País"
                      label-for="h-email"
                  >
                    <b-form-input
                        v-if="!active_select_country"
                        v-model="name_country"
                        @click="active_select_country = true"
                        placeholder="País"
                    />
                    <vue-autosuggest
                        v-else
                        :suggestions="countries"
                        :input-props="{id:'',class:'form-control', placeholder:'Seleccionar país'}"
                        @input="getCountrySelect"
                        @selected="countryVerify"

                    >
                      <template slot-scope="{suggestion}">
                        <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                      </template>
                    </vue-autosuggest>


                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Estado"
                      label-for="h-email"
                  >
                    <v-select
                        @input="selectState()"
                        v-model="select_state"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="states"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Municipios"
                      label-for="h-email"
                  >
                    <v-select
                        v-model="select_cities"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="cities"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group

                      label="Colonia"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.l10n_mx_edi_colony"
                        id="colony"
                        placeholder="Colonia"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Codigo postal"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.zip"
                        @keypress="isNumber($event)"
                        id="zip"
                        placeholder="Codigo postal"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="3">
                  <b-form-group
                      label="Calle"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.street_name"
                        id="name"
                        placeholder="Calle"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="3">
                  <b-form-group
                      label="Numero Casa"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.street_number"
                        id="num"
                        placeholder="Numero Casa"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
            <app-collapse-item title="Información de facturación">
              <b-row>
                <b-col cols="6" lg="4">
                  <b-form-group
                      label="Tipo de Paciente"
                      label-for="h-email"
                  >
                    <v-select
                        @input="pricelistVerify()"
                        v-model="select_price_list"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="pricelists"
                    />
                  </b-form-group>

                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group

                      label="Rfc"
                      label-for="h-number"
                  >
                    <b-form-input
                        v-model="user.vat"
                        id="rfc"
                        placeholder="rfc"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" lg="4">
                  <b-form-group label="Regimen Fiscal" label-for="h-email">
                    <v-select
                        v-model="regimen_select"
                        @input="changeRegimen()"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="value"
                        :options="regimens"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-col>
        <b-col cols="12" style="margin-top: 30px">
          <b-button
              style="width: 100%"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Guardar
          </b-button>

        </b-col>
      </b-row>

    </b-form>

    <b-tabs style="margin-top: 30px" v-if="user.id"  >
      <b-tab active title="Información / H.C." @click="executeInfoHistory">
        <InformationPatient title="Información / H.C." :data_patient="user" :reload="initialize"/>
      </b-tab>
      <b-tab title="Archivos">
        <FilesPatient title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Consultas" v-if="user" @click="executeConsultation">
        <ConsultationsPatient v-if="user" title="Archivos" :data_patient="user"/>
      </b-tab>
      <b-tab title="Recetas" v-if="user" @click="executeRecipe">
        <RecipePatient v-if="user" title="Archivos" :data_patient="user"/>
      </b-tab>
      <!-- <b-tab title="Esquemas" v-if="user" @click="executeSchema">
        <PatientChemotherapy v-if="user" title="Esquemas" :data_patient="user"/>
      </b-tab> -->
      <b-tab title="Compartir" v-if="user" @click="executeToShare">
        <PatientShare v-if="user" title="Compartir" :data_patient="user"/>
      </b-tab>
      <b-tab title="Cuestionario" v-if="user" @click="executeToQuestion">
        <PatientQuestionnaire v-if="user" title="Cuestionario" :data_patient="user"/>
      </b-tab>
      <b-tab title="Biocomposiciones" v-if="user" @click="executeToBio">
        <FilesBiocompositions v-if="user" title="Biocomposiciones" :data_patient="user"/>
      </b-tab>

      <b-tab title="Estudios de Laboratorio" v-if="user" @click="executeToLaboratories">
        <FilesLaboratories v-if="user" title="Estudios de Laboratorio" :data_patient="user"/>
      </b-tab>
      <b-tab title="Aseguradoras" v-if="user" @click="executeInsurers">
        <InsurersPatient v-if="user" title="Aseguradoras" :data_patient="user"/>
      </b-tab>
      <!-- <b-tab title="Archivos de tratamientos" v-if="user" @click="executeFilesTreatment">
        <FilesTreatment v-if="user" title="Archivos de tratamientos" :data_patient="user"/>
      </b-tab> -->
      <b-tab title="Inter Consulta" v-if="user" @click="executeInterConsultations">
        <InterConsultationsPatient v-if="user" title="Inter Consulta" :data_patient="user"/>
      </b-tab>
    </b-tabs>
  </b-card>

</template>

<script>

import {
  BTabs, BTab, BCard, BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BButton, BForm, BFormCheckbox, BOverlay, BFormFile
} from 'bootstrap-vue'
import {mapGetters, mapActions} from "vuex";
import PatientForms from "@/components/Patients/PatientForms";
import HistoricClinic from "@/components/Patients/HistoricClinic";
import InformationPatient from "@/components/Patients/InformationPatient";
import PatientChemotherapy from "@/components/Patients/PatientChemotherapy";
import FilesPatient from "@/components/Patients/FilesPatient";
import RecipePatient from "@/components/Patients/RecipePatient";
import ConsultationsPatient from "@/components/Patients/ConsultationsPatient";
import PatientShare from "@/components/Patients/PatientShare";
import {VueAutosuggest} from 'vue-autosuggest'
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FilesBiocompositions from "@/components/Patients/FilesBiocompositions";
import FilesLaboratories from "@/components/Patients/FilesLaboratories";
import FilesTreatment from "@/components/Patients/FilesTreatment";
import PatientQuestionnaire from "@/components/Patients/PatientQuestionnaire";
import InsurersPatient from "@/components/Patients/InsurersPatient";
import InterConsultationsPatient from "@/components/Patients/InterConsultationsPatient";

export default {
  name: "DetailPatient",
  components: {
    BFormFile,
    BOverlay,
    BForm,
    BFormCheckbox,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BButton, BFormSelect,
    PatientForms,
    vSelect,
    VueAutosuggest,
    flatPickr,
    HistoricClinic,
    InformationPatient,
    FilesPatient,
    ConsultationsPatient, RecipePatient, PatientChemotherapy, PatientShare,
    AppCollapse,
    AppCollapseItem,
    FilesBiocompositions,
    FilesLaboratories,
    PatientQuestionnaire, InsurersPatient, FilesTreatment, InterConsultationsPatient
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabs: [],
      chemo_select: {id: "", value: "Seleccione una razón"},
      extra_select: {id: "", value: "Seleccione un medico"},
      chemo_options: [
        {id: "Paciente Fallecido", value: "Paciente Fallecido"},
        {id: "Alta Paciente", value: "Alta Paciente"},
        {id: "En espera de resultado de PET CT", value: "En espera de resultado de PET CT"},
      ],
      regimen_select: {id: "", value: "Seleccione un regimen"},
      gender_select: {id: "", value: "Seleccione un genero"},
      identified_gender_select: {id: "", value: "Seleccione una opción"},
      gendersIdentified: [
        {
          id: "No especificado",
          value: "No especificado"
        },
        {
          id: "F",
          value: "Femenino"
        },
        {
          id: "M",
          value: "Masculino"
        },
        {
          id: "Transgenero",
          value: "Transgénero"
        },
        {
          id: "Transexual",
          value: "Transexual"
        },
        {
          id: "Travesti",
          value: "Travesti"
        },
        {
          id: "Intersexual",
          value: "Intersexual"
        },
        {
          id: "No-Binario",
          value: "No-Binario"
        },
        {
          id: "Otro",
          value: "Otro"
        },
      ],
      genders: [
        {
          id: "M",
          value: "Masculino"
        },
        {
          id: "F",
          value: "Femenino"
        }
      ],
      regimens: [
        {id: "601", value: "General de Ley Personas Morales"},
        {id: "603", value: "Personas Morales con Fines no Lucrativos"},
        {id: "605", value: "Sueldos y Salarios e Ingresos Asimilados a Salarios"},
        {id: "606", value: "Arrendamiento"},
        {id: "608", value: "Demás ingresos"},
        {id: "609", value: "Consolidación"},
        {id: "610", value: "Residentes en el Extranjero sin Establecimiento Permanente en México"},
        {id: "611", value: "Ingresos por Dividendos (socios y accionistas)"},
        {id: "612", value: "Personas Físicas con Actividades Empresariales y Profesionales"},
        {id: "614", value: "Ingresos por intereses"},
        {id: "616", value: "Sin obligaciones fiscales"},
        {id: "620", value: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos"},
        {id: "621", value: "Incorporación Fiscal"},
        {id: "622", value: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras"},
        {id: "623", value: "Opcional para Grupos de Sociedades"},
        {id: "624", value: "Coordinados"},
        {id: "628", value: "Hidrocarburos"},
        {id: "607", value: "Régimen de Enajenación o Adquisición de Bienes"},
        {id: "629", value: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales"},
        {id: "630", value: "Enajenación de acciones en bolsa de valores"},
        {id: "615", value: "Régimen de los ingresos por obtención de premios"},
        {
          id: "625",
          value: "Régimen de las actividades empresariales con ingresos a traves de plataformas inalambricas"
        },
        {id: "626", value: "Régimen Simplificado de confianza"},
      ],
      select_state: {id: '', value: ''},
      select_cities: {id: '', value: ''},
      select_medical: {id: '', value: 'Seleccione un medico'},
      countries: [],
      name_country: '',
      active_select_country: false,
      allergiesInfo: null,
      states: [],
      cities: [],
      pricelists: [],
      medicals: [],
      idactive: null,
      avatarImage: null,
      avatarImageTmp: require('@/assets/images/avatar.jpeg'),
      select_group: {id: '', value: ''},
      groups: [],
      patient_id: null,
      show: false,
      select_price_list: {id: '', value: ''},
      get_country: '',
      user: {
        id: '',
        name: '',
        reason_suspension: '',
        patient_file_number: '',
        comments_suspension: '',
        gender: '',
        l10n_mx_edi_fiscal_regime: '',
        property_product_pricelist: '',
        phone: '',
        mobile: '',
        email: '',
        function: '',
        appointee_id: '',
        is_patient: true,
        chemo_suspension: false,
        street: '',
        street_number: '',
        city_id: '',
        state_id: '',
        country_id: '',
        zip: '',
        l10n_mx_edi_colony: '',
        l10n_mx_edi_locality: '',
        vat: '',
        check_medical: false,
        extra_medical_id: null,
        identified_gender: null,
      }
    }
  },
  async created() {
    await this.initialize();
  },

  methods: {
    ...mapActions('patient', ['createPatient', 'findConsecutiveId', 'findPatientOne',
      'updatePatient', 'findPricelistPatient', 'findPatientOneInformation', 'createInformationGeneral','updatePhotoPatient']),
    ...mapActions('appointment', ['findAppointmentDoctor', 'findAppointmentGroups', 'findApointeeSearch', 'findAppointmentDoctorPlace', 'findAppointmentPriceList']),
    ...mapActions('clinic_patient', ['nameClinic']),
    ...mapActions('address', ['findCountry', 'findCountryState', 'findCountryCities']),
    async saveImage() {

    },
    async initialize() {
      await this.findDoctor()
      await this.getOne();
      await this.tabsFind()
      await this.getPriceListSelect()

      await this.patientVerify(this.user.id)
      await this.verifyInfo(this.patient_id);
    },
    async verifyInfo(id) {
      const response = await this.findPatientOneInformation(id);
      this.allergiesInfo = null;
      if (response) {
        if (!response.have_allergies) {
          this.allergiesInfo = response.allergies
        }
      }

    },
    async patientVerify(id) {

      const response = await this.findPricelistPatient(id);


      this.select_price_list.id = response.id
      this.select_price_list.value = response.name
      this.user.property_product_pricelist = response.id

    },
    changeChemoValue() {
      if (this.user.chemo_suspension === '') {
        this.user.chemo_suspension = true
        return
      }
      this.user.reason_suspension = ''

    },
    changeExtraValue() {
      if (this.user.check_medical === '') {
        this.user.check_medical = true
        return
      }
      this.user.extra_medical_id = null
      this.extra_select.id = null
      this.extra_select.value = 'Seleccione un médico'

    },
    async executeToShare() {
      await this.$root.$emit('chargeShare')
    },
    async executeToLaboratories() {
      await this.$root.$emit('chargeLaboratories')
    },
    async executeToBio() {
      await this.$root.$emit('chargeBio')
    },
    async executeToQuestion() {
      await this.$root.$emit('chargePatientQuestion')
    },
    async executeSchema() {
      await this.$root.$emit('chargeSchema')
    },
    async executeInsurers() {
      await this.$root.$emit('chargeInsurers')
    },
    async executeFilesTreatment() {
      await this.$root.$emit('chargeFilesTreatment')
    },
    async executeInterConsultations() {
      await this.$root.$emit('chargeInterConsultation')
    },
    async executeConsultation() {
      await this.$root.$emit('chargeConsultation')
    },
    async executeInfoHistory() {
      await this.$root.$emit('chargePatientHistory')
    },
    async executeRecipe() {
      await this.$root.$emit('chargeRecipe')
    },
    changeRegimen() {
      this.user.l10n_mx_edi_fiscal_regime = this.regimen_select.id;
    },
    changeGender() {
      this.user.gender = this.gender_select.id;
    },
    changeIdentifiedGender() {
      this.user.identified_gender = this.identified_gender_select.id;
    },
    async getPriceListSelect() {

      this.pricelists = []
      const response = await this.findAppointmentPriceList()
      for (const a of response) {
        this.pricelists.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async tabsFind() {
      this.tabs = await this.nameClinic()
    },
    async pricelistVerify() {
      this.user.property_product_pricelist = this.select_price_list.id;
    },

    async groupsVerify() {
      await this.searchGroup(this.select_group.id)
    },
    activeTab(id) {

      this.idactive = id

    },
    async getOne() {
      let regimen = null
      let gender = null
      const id = this.$route.params.id;

      this.patient_id = parseInt(id);
      const response = await this.findPatientOne(id);
      this.avatarImage = response.photo_user;

      if (response.l10n_mx_edi_fiscal_regime) {
        for (const a of this.regimens) {
          if (a.id === response.l10n_mx_edi_fiscal_regime) {
            regimen = a.value
          }
        }
        this.regimen_select.id = response.l10n_mx_edi_fiscal_regime
        this.regimen_select.value = regimen
      } else {
        this.regimen_select.id = ''
        this.regimen_select.value = ''
      }
      if (response.gender) {
        for (const a of this.genders) {
          if (a.id === response.gender) {
            gender = a.value
          }
        }
        this.gender_select.id = response.gender
        this.gender_select.value = gender
      } else {
        this.gender_select.id = ''
        this.gender_select.value = ''
      }
      if (response.identified_gender) {
        for (const a of this.gendersIdentified) {
          if (a.id === response.identified_gender) {
            gender = a.value
          }
        }
        this.identified_gender_select.id = response.gendersIdentified
        this.identified_gender_select.value = gender
      } else {
        this.identified_gender_select.id = ''
        this.identified_gender_select.value = ''
      }
      if (response.chemo_suspension) {
        const result = this.chemo_options.find((a) => a.id === response.reason_suspension);
        this.chemo_select.id = result.id;
        this.chemo_select.value = result.value;
      } else {
        this.chemo_select.id = ''
        this.chemo_select.value = 'Seleccione una razón'
      }
      if (response.check_medical) {
        const result = this.medicals.find((a) => a.id === response.extra_medical_id);
        this.extra_select.id = result.id;
        this.extra_select.value = result.value;
      } else {
        this.extra_select.id = ''
        this.extra_select.value = 'Seleccione un Médico'
      }
      this.user = response;
      if (!this.user.patient_file_number) {
        this.user.patient_file_number = await this.findConsecutiveId();
      }
      if (response.ResCountry) {
        await this.verifyCountry(response)
      }

      await this.verifyDoctor(response)
    },
    async verifyCountry(data) {
      this.name_country = data.ResCountry ? data.ResCountry.name : ''
      this.select_state.id = data.ResCountryState ? data.ResCountryState.id : ''
      this.select_state.value = data.ResCountryState ? data.ResCountryState.name : ''
      if (data.ResCity) {
        this.select_cities.value = data.ResCity.name
        this.select_cities.id = data.ResCity.id
      } else {
        this.select_cities.value = ''
        this.select_cities.id = ''
      }
      const item = {
        item: {
          id: data.ResCountry.id
        }
      }
      await this.countryVerify(item)
    },

    async verifyDoctor(data) {
      this.select_medical.id = data.ResPartnerAppointment.id
      this.select_medical.value = data.ResPartnerAppointment.name


    },
    async getGroupSelect() {
      this.groups = []
      const response = await this.findAppointmentGroups()
      for (const a of response) {
        if (!a.is_place) {
          this.groups.push({
            id: a.id,
            value: a.group_name,
            verifed: a.is_place
          })
        }

      }
    },
    changeMedical() {
      this.user.appointee_id = this.select_medical.id;
    },
    change_chemo() {
      this.user.reason_suspension = this.chemo_select.id;
    },
    change_extra_medical() {
      this.user.extra_medical_id = this.extra_select.id;
    },
    async searchGroup(id) {
      const response = await this.findApointeeSearch(id);
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }

    },
    async findDoctor() {
      const response = await this.findAppointmentDoctorPlace()
      this.medicals = []
      for (const a of response) {
        this.medicals.push({
          id: a.ResPartnerAppointment.id,
          value: a.ResPartnerAppointment.name,
        })
      }
    },
    async verifiedForm() {

      if (this.user.name === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo nombre requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.phone) {
        if (this.user.phone.length !== 10) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo Telefono necesita 10 caracteres",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }
      }
      if (this.user.email !== "") {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(this.user.email) === false) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Campo email no es un correo",
              icon: "error",
              variant: "warning",
            },
          });
          return false;
        }

      }
      if (!this.user.birthday) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo fecha de nacimiento requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.mobile) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Celular requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.mobile.length !== 10) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Campo Celular necesita 10 caracteres",
            icon: "error",
            variant: "warning",
          },
        });
        return false;
      }
      if (!this.user.country_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Pais requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.state_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo estado  requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.city_id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo municipio requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (!this.user.property_product_pricelist) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Campo Tipo de Paciente requerido',
            icon: 'error',
            variant: 'warning',
          },
        });
        return false
      }
      if (this.user.chemo_suspension) {
        if (!this.user.reason_suspension) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo Razón no debe ir vacio',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false
        }
      }
      if (this.user.check_medical) {
        if (!this.user.extra_medical_id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Campo doctor extra es requerido',
              icon: 'error',
              variant: 'warning',
            },
          });
          return false
        }
      }
      return true


    },
    async handleImage() {
      const file = document.querySelector(`#file_patient_photo`).files[0];

      const file_convert = await this.getBase64(file)

      file_convert.onload = async (event) => {
        this.show = true;
        this.avatarImageTmp = event.target.result;
        const response = await this.updatePhotoPatient({id:this.patient_id, data:{file:  this.avatarImageTmp }})
        if (response){
          this.show = false;
          this.avatarImage = event.target.result;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Imagen se subio correctamente',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }else{
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al subir la imagen',
              icon: 'error',
              variant: 'warning',
            },
          });
        }
      };
    },
    async getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return reader
    },
    async updateUser(user) {
      this.user.city_id = this.select_cities.id;
      this.user.reason_suspension = this.user.chemo_suspension ? this.chemo_select.id : null;
      this.user.comments_suspension = this.user.chemo_suspension ? this.user.comments_suspension : '';
      let response;
      delete this.user.ResCountryState
      delete this.user.ResCity
      delete this.user.ResCountry
      delete this.user.ResPartnerAppointment
      const re = await this.verifiedForm()
      if (!re) {
        return
      }
      this.show = true
      response = await this.updatePatient(this.user)

      if (response.msg) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario Editado',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.findDoctor()
        await this.getOne();
        await this.tabsFind()
        await this.getPriceListSelect()

        await this.patientVerify(this.user.id)
        await this.verifyInfo(this.patient_id);
        this.show = false

      } else {
        this.show = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error,
            icon: 'error',
            variant: 'warning',
          },
        });

      }
    },
    async countryVerify(item) {
      this.user.country_id = item.item.id
      this.states = []
      const response = await this.findCountryState(this.user.country_id);
      for (const a of response) {
        this.states.push({
          id: a.id,
          value: a.name,
        })
      }
      await this.searchCities()
    },
    async searchCities() {
      this.cities = []
      let data = {}
      if (this.user.state_id) {
        data = {
          country_id: this.user.country_id,
          state_id: this.user.state_id
        }
      } else {
        data = {
          country_id: this.user.country_id,
          state_id: null
        }
      }
      const response = await this.findCountryCities(data);
      for (const a of response) {
        this.cities.push({
          id: a.id,
          value: a.name,
        })
      }
    },
    async selectState() {
      this.user.state_id = this.select_state.id;

      await this.searchCities()
    },
    async getCountrySelect(text) {
      if (text === '' || text === undefined) {
        this.countries = []
        return
      }
      this.countries = []
      let cou = []
      const response = await this.findCountry(text);
      for (const a of response) {
        cou.push({
          id: a.id,
          name: a.name,
        })
      }
      this.countries = [{
        data: cou,
      }]

    }

  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
